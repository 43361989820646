import React, { useState, useEffect } from 'react';
import { getPricingCountOfSKU, getPricingBC, getPricingFluctSellout, getPriceFluctSummaryTable, getPriceFluctTopContTable,getCompetitorKeyList,
    getPricingCountOfSKUPCompetiton, getPricingBCompetition, getPricingCountOfSKUPW,getPricingBCPW,getPricingSalesPW,getSummaryTablePW, getSummaryTablePriceCompetition, getTopContTablePW, getReportDetailsByMetricName, getManufactoresList, getPricingSalesPCompetition, getTopContTablePCompetition} from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Table } from "react-bootstrap";
import moment from "moment";
import {
    LineChart,  XAxis, Tooltip, YAxis,
    Legend, CartesianGrid, Line, Area, ResponsiveContainer, ComposedChart
} from "recharts";
import BarLoader from "react-spinners/BarLoader";
import { ExcelfileIcon, CarretIconUp, CarretIconDown } from '../../components/Icons';
import SentimentReportModal from './SentimentReportModal';
import { convertToKMB, PriceFormatter } from '../../helper';
import { Buffer } from 'buffer';

const PriceFluctuation = () => {
    let dispatch = useDispatch();
    const [firstloading, setFirstLoading] = useState();
    const [loading, setLoading] = useState();
    const [activeTab, setActiveTab] = useState('tab1');
    const [priceCountSKUS, setPriceCountSKUS] = useState([]);
    const [priceBCData, setPriceBCData] = useState([]);
    const [priceTableData, setPriceTableData] = useState([]);
    const [priceWpfData, setPriceWpfData] = useState([]);
    const [priceContTableData, setPriceContTableData] = useState([]);
    const { selectedCustomerKey, selectedGlobalFilters, getDecimalRetailersData, selectedRetailerCurrency, project, subproject } = useSelector((state) => state.globalReducer);
    const { selectedSelectionAndMetricName, insightsTabsDetailsData, priceWarTopContributionTable, priceWarSummaryTable, priceWarCompareRetailer, priceWarCountofSKU,
        priceWarBusinessCont, priceWarSellOut, priceCompetitonRetailer, priceCompetationCountofSKU, priceCompetationBusinessCnt, priceCompetitionSummaryTable, priceCompetitionSellOut, priceContributionTopContributionTable } = useSelector((state) => state.insightReducer);
            const {insightsDateValues}
        = useSelector((state) => state.globalReducer);
    const { dateFilterAndRange } = useSelector((state) => state.newRecommendationTableData);
    let dateFilterRange = dateFilterAndRange?.dateRange ? dateFilterAndRange.dateRange : 'MTD';
    const dateFormat = (dateVal) => moment(dateVal).format('D-M-YYYY');
    const metricNamelist = insightsTabsDetailsData?.finalData?.METRIC_NAME.split(',');
    const metricListName = metricNamelist?.length > 0 ? activeTab === 'tab1' ? metricNamelist[0] : activeTab === 'tab3' ? metricNamelist[1] :activeTab === 'tab2' ? metricNamelist[2] :'' : '';

    const [displayModal, setDisplayModal] = useState(false);
    const [modalDetails, setModalDetails] = useState({});
    const comparePBIVal = priceWarCompareRetailer?.responseData?.length ? priceWarCompareRetailer?.responseData[0] : [];
    const [compareVal, setCompareVal] = useState(priceWarCompareRetailer?.responseData?.length ? priceWarCompareRetailer?.responseData[0]?.COMPKEY : []);
    const [compareName, setCompareName] = useState(priceWarCompareRetailer?.responseData?.length ? priceWarCompareRetailer?.responseData[0]?.COMPNAME : []);

    const competationPBIVal = priceCompetitonRetailer?.responseData?.length > 0 ? priceCompetitonRetailer?.responseData[0] : [];
    const [priceCompetitonVal, setPriceCompetitonVal] = useState(competationPBIVal.COMPNAME);
    const [priceCompetitonName, setPriceCompetitonName] = useState(competationPBIVal.COMPNAME);
    
    const displayModalOff = () => setDisplayModal(false);    
    const handleTabChange = (tabKey) => {
        if(tabKey === "tab2"){
            setPriceCompetitonVal(competationPBIVal.COMPNAME);
        }
        setActiveTab(tabKey);         
    }   
   
    const updatedDataObj = { ...selectedGlobalFilters };
    const removeValueFromObjectArray = (obj) => {
        const key = 'rpln_flag';
        if (obj.hasOwnProperty(key) && obj[key].length > 0) {
        delete obj[key];
        }
        return obj;
    }
    const updatedObject = removeValueFromObjectArray(updatedDataObj);
    const responseHandler = (res)=> {
        setTimeout(() => setFirstLoading(false), 600);
        setTimeout(() => setLoading(false), 600);    
    }

    useEffect(() => {
        setFirstLoading(true);
        dispatch(getManufactoresList({customerKey: selectedCustomerKey},responseHandler));
        dispatch(getCompetitorKeyList({customerKey: selectedCustomerKey},responseHandler));
    }, []);
   
    useEffect(() => {
        console.log("Test")
        setLoading(true);
        if (activeTab === 'tab1') {            
            dispatch(getPricingCountOfSKU({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName}, (res) => {
                    if (res?.data?.responseType === 'success') {
                        setPriceCountSKUS(res?.data?.responseData);
                    } else {
                        setPriceCountSKUS([])
                    }
                 }));
            dispatch(getPricingBC({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName}, (res) => { 
                    if (res?.data?.responseType === 'success') {
                        setPriceBCData(res?.data?.responseData);
                    } else {
                        setPriceBCData([])
                    }setLoading(false);
                }));
            dispatch(getPricingFluctSellout({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName}, (res) => { 
                    if (res?.data?.responseType === 'success') {
                        setPriceWpfData(res?.data?.responseData);
                    } else {
                        setPriceWpfData([])
                    }
                }));
            dispatch(getPriceFluctSummaryTable({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName}, (res) => { 
                    if (res?.data?.responseType === 'success') {
                    setPriceTableData(res?.data?.responseData);
                    } else {
                        setPriceTableData([])
                    }
                    }));
            dispatch(getPriceFluctTopContTable({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange}, (res) => { 
                    if (res?.data?.responseType === 'success') {
                        setPriceContTableData(res?.data?.responseData);
                    } else {
                        setPriceContTableData([])
                    }                    
                }));
        }
        if (activeTab === 'tab2') {        
            dispatch(getPricingCountOfSKUPCompetiton({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName, manufactorName: priceCompetitonVal}, responseHandler));
            dispatch(getPricingBCompetition({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName, manufactorName: priceCompetitonVal}, responseHandler)); 
            dispatch(getSummaryTablePriceCompetition({
                    customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                    sectionName: selectedSelectionAndMetricName.sectionName, manufactorName: priceCompetitonVal}, responseHandler));         
            dispatch(getPricingSalesPCompetition({
                    customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                    sectionName: selectedSelectionAndMetricName.sectionName, manufactorName: priceCompetitonVal}, responseHandler));
            dispatch(getTopContTablePCompetition({
                    customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                    sectionName: selectedSelectionAndMetricName.sectionName, manufactorName: priceCompetitonVal}, responseHandler));
                        
        }
            if (activeTab === 'tab3') {            
            dispatch(getPricingCountOfSKUPW({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName, competitorKey: compareVal}, responseHandler));
            dispatch(getPricingBCPW({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName, competitorKey: compareVal}, responseHandler));
            dispatch(getPricingSalesPW({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName, competitorKey: compareVal}, responseHandler));
            dispatch(getSummaryTablePW({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName, competitorKey: compareVal}, responseHandler));
            dispatch(getTopContTablePW({
                customerKey: selectedCustomerKey, filterValues: updatedObject, timeFrame: dateFilterRange,
                sectionName: selectedSelectionAndMetricName.sectionName, competitorKey: compareVal}, responseHandler));    
        }
            
    }, [selectedGlobalFilters, dateFilterAndRange?.dateRange, activeTab, compareVal, priceCompetitonVal]);   
    
    function numberWithCommas(val) {
        let resultNum = val !== null && val !== undefined && val !== 'NaN' ? parseFloat(val).toFixed(0) : 0;
        return resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      const numberWithCommastwodecimal = (val) => {
        let resultNum = val !== null && val !== undefined && val !== 'NaN' ? parseFloat(val).toFixed(2) : 0;
        return resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    const numberPriceMult = (val) => {
        let PricevalChange = val * 100;
        let resultNum = val !== null && val !== undefined && val !== 'NaN' ? parseFloat(PricevalChange).toFixed(0) : 0;
        return resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      const numberPriceMulttwodecimal = (val) => {
        let PricevalChange = val * 100;
        let resultNum = val !== null && val !== undefined && val !== 'NaN' ? parseFloat(PricevalChange).toFixed(2) : 0;
        return resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    const numberPricewithoutMulti = (val) => {
        let resultNum = val !== null && val !== undefined && val !== 'NaN' ? parseFloat(val).toFixed(0) : 0;
        return resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const labelFormatterNumcupr = (num) => {
        if (num !== null) {
            return num >= 1000000000 ? (parseFloat(num) / 1000000000).toFixed(0).replace(/\.0$/, '') + 'B' : num >= 1000000 ? (parseFloat(num) / 1000000).toFixed(0).replace(/\.0$/, '') + 'M' :
            num >= 1000 ? (parseFloat(num) / 1000).toFixed(0).replace(/\.0$/, '') + 'K' : num < 1000 ? parseFloat(num).toFixed(0).replace(/\.0$/, '') : num;
        }
    };

    const CurrencyFormatterVal = (num) =>  num !== null && num !== undefined && num !== 'NaN' ? convertToKMB(num, selectedRetailerCurrency) : selectedRetailerCurrency + '0'; 
    const decimalCurrencyFormat = (num) =>  num !== null && num !== undefined && num !== 'NaN' ? PriceFormatter(num, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol : selectedRetailerCurrency}) : selectedRetailerCurrency +'0';
    const numPriceRangeFormatPre = (val,row) => { 
        let resultNumhigh = 0, resultNumlow = 0,
        prevpricehigh = row?.PRICE_RANGE_PREV_HIGH, prevpricelow = row?.PRICE_RANGE_PREV_LOW;
        if(prevpricehigh) {
           let resPrehigh = prevpricehigh !== null && prevpricehigh !== undefined && prevpricehigh !== 'NaN' ? parseFloat(prevpricehigh) : 0;
           resultNumhigh =  PriceFormatter(resPrehigh, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol : selectedRetailerCurrency});
        }
        if(prevpricelow) {
           let resultPrelow = prevpricelow !== null && prevpricelow !== undefined && prevpricelow !== 'NaN' ? parseFloat(prevpricelow) : 0;
           resultNumlow =  PriceFormatter(resultPrelow, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol : selectedRetailerCurrency});
        }        
        return `${resultNumhigh} - ${resultNumlow}`;
    }

    const numPriceRangeFormatcur = (val,row) => {
        let resultNumhigh = 0, resultNumlow = 0,
        priceRangehigh = row?.PRICE_RANGE_CURR_HIGH, priceRangelow = row?.PRICE_RANGE_CURR_LOW;
        if(priceRangehigh) {            
           let resPrehigh = priceRangehigh !== null && priceRangehigh !== undefined && priceRangehigh !== 'NaN' ? parseFloat(priceRangehigh) : 0;
           resultNumhigh = PriceFormatter(resPrehigh, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol : selectedRetailerCurrency});
        }
        if(priceRangelow) {
           let resultPrelow = priceRangelow !== null && priceRangelow !== undefined && priceRangelow !== 'NaN' ? parseFloat(priceRangelow) : 0;
           resultNumlow =  PriceFormatter(resultPrelow, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol : selectedRetailerCurrency});
        }        
        return `${resultNumhigh} - ${resultNumlow}`;
    }
    const numberPriceFormat = (val) => {
        let resultNum = val !== null && val !== undefined && val !== 'NaN' ? parseFloat(val).toFixed(0) : 0;
        let valFormat = resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${selectedRetailerCurrency}${valFormat}`
      }
    const labelFormatterNumcuprwtsell = (num) => num !== null ? parseFloat(num).toFixed(2) : '';
    const labelFormatterFixedoneDeci = (num) => num !== null ? parseFloat(num).toFixed(1) : '';
    const retailerdataFormat = (cell) => cell !== null ? cell : 'N/A';
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              {payload.map((pld, index) => (
                <>
                  {index === 0 &&
                    <div key={index} style={{ display: "inline-block", backgroundColor: "#000", color: "#fff", padding: "10px 15px" }}>
                      {pld.payload.TIMEFRAME && !pld.payload.TIME ? <div><span className='' >DATE:</span> {pld.payload.TIMEFRAME}</div> : ''}
                      {pld.payload.TIME ? <div>DATE: {pld.payload.TIME}</div> : ''}
                      {pld.payload.I ? <div>Price Increase: {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.I)+'%' : numberWithCommastwodecimal(pld.payload.I)+'%'} </div> : ''}
                      {pld.payload.D ? <div>Price Decrease: {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.D)+'%' : numberWithCommastwodecimal(pld.payload.D)+'%'}</div> : ''}
                      {pld.payload.E ? <div>Price Equal: {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.E)+'%' : numberWithCommastwodecimal(pld.payload.E)+'%'}</div> : ''}
                      {pld.payload.HP ? <div>Higher Price: {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.HP)+'%' : numberWithCommastwodecimal(pld.payload.HP)+'%'}</div> : ''}
                      {pld.payload.LP ? <div>Lower Price: {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.LP)+'%' : numberWithCommastwodecimal(pld.payload.LP)+'%'}</div> : ''}
                      {pld.payload.WIN ? <div>Within range(+/-5%): {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.WIN)+'%' : numberWithCommastwodecimal(pld.payload.WIN)+'%'}</div> : ''}
                      {pld.payload.NP ? <div>Without Price: {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.NP)+'%' : numberWithCommastwodecimal(pld.payload.NP)+'%'}</div> : ''}
                      {pld.payload.WPW ? <div>Without Price: {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.WPW)+'%' : numberWithCommastwodecimal(pld.payload.WPW)+'%'}</div> : ''}
                      {pld.payload.NCP ? <div>No Competition: {pld.payload.TIME ? numberPriceMulttwodecimal(pld.payload.NCP)+'%' : numberWithCommastwodecimal(pld.payload.NCP)+'%'}</div> : ''}
                      {pld.payload.SI ? <div>Sellout Index: {numberWithCommas(pld.payload.SI)}</div> : ''}                  
                      {pld.payload.TIMEFRAME_VAL ? <div>Date: {pld.payload.TIMEFRAME_VAL} </div> : ''}
                      {pld.payload.SELL_OUT ? <div>Sellout Index: {labelFormatterNumcuprwtsell(pld.payload.SELL_OUT)} </div> : ''}
                      {pld.payload.SALES ? <div>Sales: {labelFormatterNumcuprwtsell(pld.payload.SALES)} </div> : ''}
                      {pld.payload.WEIGHTED_PRICE_INDEX ? <div>Weighted Price Index: {labelFormatterNumcuprwtsell(pld.payload.WEIGHTED_PRICE_INDEX)} </div> : ''}                      
                    </div>}
                </>
              ))}
            </div>
          );
        }
        return null;
      };
    
    const labelWithoutFixedVal = (num) => num !== null ? parseFloat(num).toFixed(0) : '';
    const dataValueformat = (cell) =>  cell !== null && cell !== undefined && cell !== 'NaN' ? parseFloat(cell).toFixed(0)+'%' : '0%';
    const dataValueformatBucont = (cell) =>  cell !== null && cell !== undefined && cell !== 'NaN' ? parseFloat(cell).toFixed(2)+'%' : '0%';
    const dataCountformat = (cell) => cell !== null && cell !== undefined && cell !== 'NaN' ? parseFloat(cell).toFixed(0) : 0;
    const firstWordUpper = (cell) => {
        if(cell !== null) {
            let lowerCase = cell.toLowerCase(),
            lrcase = lowerCase.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()); 
           return lrcase === 'No Comparision Available' ? 'No Competition' : lrcase === 'Without Price(k-c)'? 'Without Price' : lrcase;
        }
       
    }
    const dataPopValueformat = (cell) => {        
        if (cell !== null && cell !== undefined && cell !== 'NaN') {
            let cellVal = parseFloat(cell).toFixed(0);
            if (cell >= 0) {
                return <span className='clr-grn d-inline-flex'><CarretIconUp />{cellVal}% </span>
            } else {
                return <span className='clr-rd d-inline-flex'><CarretIconDown />{cellVal * -1}%</span>
            }
        } else {
            return '0%';
        }
    };
    const dataCompareValueformat  = (val,row) => {
        let resultNumhigh = 0, resultNumlow = 0;
        const currentVal = val !== null && val !== undefined && val !== 'NaN' ? parseFloat(val).toFixed(0) : 0;
        const priceRangehigh = row?.PI_CP, priceRangelow = row?.PI_PP;
        if (priceRangehigh - priceRangelow > 0) {  
            return <span className='clr-rd d-inline-flex'>{currentVal}</span>          
        }
        else  {
            return <span className='clr-grn d-inline-flex'>{currentVal} </span>
        }        
    };
    const columnClassNameFormat = (row) => {
        let rowStatus = row?.STATUS;
        if(rowStatus || row?.RPC_TIME_OU) {
            return rowStatus === 'I' || rowStatus === 'HP' ? 'td-column-increase' : rowStatus === 'D' || rowStatus === 'LP' ? 'td-column-decrease' : rowStatus === 'E' || rowStatus === 'WIN' ? 'td-column-equal' : rowStatus === 'WPW' || rowStatus === 'NP' ? 'td-column-wp' : 'td-column-noprice';
        }
    }    
    const dataStatusformat = (cell) => {
        if(cell !== null) {
         return <b className='tdstatuscls'> {cell === 'I' ? 'Increase' : cell === 'D' ? 'Decrease' : cell === 'E' ? 'Equal' : cell === 'NP' || cell === 'WPW' ? 'Without Price'
          : cell === 'HP' ? 'Higher Price' : cell === 'LP' ? 'Lower Price' :  cell === 'WIN' ? 'Within range(+/-5%)' : cell === 'NCP' ? 'No Competition' : ''}</b>;
        }
    }
    const dataProducts = (cell,row) => {
        if(cell !== null) {
            return `${cell} - ${row?.ITEM_ID}`
        }
    }
    const viewSkuDetails = () => {
        dispatch(getReportDetailsByMetricName({metricName: metricListName, customerKey: selectedCustomerKey, },
            (res) => {
                if(activeTab !== 'tab1'){
                    let obj = {        
                        pbiCompareValue: activeTab === 'tab2' ? priceCompetitonVal : compareVal
                      }
                    setModalDetails(obj);
                } 
                setDisplayModal(true);
            }))
    };
    
    const downloadData = (type) => {
        let combinedObj = {
            customerKey: selectedCustomerKey,
            filterValues: updatedObject,
            timeFrame: dateFilterRange,
            dateRange:insightsDateValues,
            projectName: project,
            subprojectName: subproject
        },
        bufferValue = Buffer.from(JSON.stringify(combinedObj)),
        base64data = bufferValue.toString("base64"),
        priceTopContrExcelAPI = type === 'pwTableData'? 'getPriceWarTopContExcel' : type === 'pfTableData' ? 'getPriceFluctTopContExcel' : 'getPriceCompetitonTopContExcel'; 
        window.open(`${process.env.REACT_APP_ROOT_URL}/download/${priceTopContrExcelAPI}/${base64data}`, "_blank");
        console.log("tests")
    }

    const PricingTab = () => {
        return (
            <div>                
                <div className='text-center mt-3'>
                    <div className='font-weight-bold pricingheader_title'>Price fluctuation</div>
                    <div>Based on SKUs with Price information for more than 50% on the last 12 months</div>
                </div>
                {loading ? loadpage() : 
                <>               
                <div className='row mt-4 ml-2 mr-2'>
                    <div className='col-6 pl-0 pr-0'>
                        <div className='font-weight-bold f-20 text-center pb-2'>Count of SKUs</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <ComposedChart width={600} data={priceCountSKUS} margin={{top:12, bottom: 35,right:-20}}>
                                <XAxis dataKey="TIMEFRAME"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} minTickGap={-200} axisLine={false} dy={1} />
                                <YAxis domain={['dataMin', 'dataMax']}  yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} /> 
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} />
                                <CartesianGrid stroke="#e8eaec" />
                                <Area yAxisId="left" dataKey="I" isAnimationActive={false} name='Price Increase'
                                 fill="#a4de6c" stackId="1" stroke="#a4de6c" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="D" isAnimationActive={false} name='Price Decrease'
                                  stroke="#ffb3b3" stackId="1" fill="#ffb3b3" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="E" isAnimationActive={false} name='Price Equal'
                                    stroke="#919695" stackId="1" fill="#919695" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="NP" isAnimationActive={false} name='Without Price'
                                    stroke="#ffd11a" stackId="1" fill="#ffd11a" strokeWidth="0" dot={false} />
                                <Line yAxisId="right" dataKey="SI" isAnimationActive={false} name='Sellout Index' 
                                    stroke="#ff7300" fill='#ff7300' strokeWidth="2" dot={false} />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px", fontSize:'16px',paddingLeft:'10px',paddingRight:'10px' }} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='col-6 pl-0 pr-0'>
                    <div className='font-weight-bold f-20 text-center pb-2'>Business Contribution</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <ComposedChart width={600} data={priceBCData} margin={{top:12, bottom: 35, right:-20 }}>
                            <XAxis dataKey="TIME"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} dy={1} minTickGap={-200} axisLine={false}/>
                                <YAxis domain={['dataMin', 'dataMax']}  yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={numberPriceMult} />
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={numberPricewithoutMulti} />
                                <CartesianGrid stroke="#e8eaec" />
                                <Area yAxisId="left" dataKey="I" isAnimationActive={false} name='Price Increase'
                                 fill="#a4de6c" stroke="#a4de6c" stackId="1" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="D" isAnimationActive={false} name='Price Decrease' 
                                    stroke="#ffb3b3" fill="#ffb3b3" stackId="1" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="E" isAnimationActive={false} name='Price Equal' 
                                    stroke="#919695" fill="#919695" stackId="1" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="NP" isAnimationActive={false} name='Without Price'
                                    stroke="#ffd11a" fill="#ffd11a" stackId="1" strokeWidth="0" dot={false} />
                                <Line yAxisId="right" dataKey="SI" isAnimationActive={false} name='Sellout Index' 
                                    stroke="#ff7300" fill='#ff7300' strokeWidth="2" dot={false} />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px",fontSize:'16px',paddingLeft:'10px',paddingRight:'10px' }} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
               
                <div className='row mt-2 ml-2 mr-2'>                              
                    <div className='col-6 insightpricetable pl-5'> 
                    <div className='font-weight-bold f-20 text-center pt-5 pb-3'></div>                       
                    <Table bordered className='table_center'>
                                    <thead>
                                        <tr className='tablerow_first'>
                                            <th rowSpan="1"></th>
                                            <th colSpan="2">SKU Count</th>
                                            <th colSpan="2">Business Contribution</th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span onClick={viewSkuDetails} className='newValueformat' >View SKU Details</span>
                                            </th>
                                            <th>Count</th>
                                            <th>PoP</th>
                                            <th>Value</th>
                                            <th>PoP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {priceTableData.length > 0 ? <>
                                        {priceTableData.map((row, index) => (
                                            <tr key={index}>
                                                <td>{firstWordUpper(row.VIEW)}</td>
                                                <td>{dataCountformat(row.CNT_SKU)}</td>
                                                <td>{dataPopValueformat(row.POP_SKU)}</td>
                                                <td>{dataValueformatBucont(row.CNT_BC)}</td>
                                                <td>{dataPopValueformat(row.POP_BC)}</td>
                                            </tr>
                                        ))} </>: <tr> <td colSpan="5" style={{ textAlign: 'center' }}>No data available</td></tr>}
                                    </tbody>
                                </Table>
                    </div>
                    <div className='col-6 pl-0 pr-0'>
                    <div className='font-weight-bold f-20 text-center pb-1'>Weighted Price Fluctuation vs Sellout</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <LineChart data={priceWpfData} margin={{top:12, bottom: 35, right:-20 }}>
                                <XAxis dataKey="TIMEFRAME_VAL"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} dy={1} minTickGap={-200} axisLine={false}/>
                                <YAxis yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} />
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={labelFormatterFixedoneDeci} />
                                <CartesianGrid stroke="#e8eaec" />
                                <Line yAxisId="left" dataKey="SELL_OUT" isAnimationActive={false} name='Sellout Index'
                                    stroke="#ed853e" strokeWidth="2.5" dot={ false } />
                                <Line yAxisId="right" dataKey="WEIGHTED_PRICE_INDEX" isAnimationActive={false} name='Weighted Price Index'
                                    stroke="#6b8fd0" strokeWidth="2.5" dot={ false } />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px",fontSize:'16px', paddingLeft:'10px',paddingRight:'10px' }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className='insightpricetable price-top-cont m-3'> 
                    <div><div className='text-center f-24 font-weight-bold'>Top Contributors</div> <div onClick={() => downloadData('pfTableData')} className='text-right downloadfile'>Download All SKUs <ExcelfileIcon /></div></div>
                    <div className={priceContTableData?.length ? 'tablecls_scrl' : ''}>
                    <Table bordered className='table_center' >
                                <thead className='sticky-top'>
                                    <tr className='tablerow_first'>
                                        <th rowSpan="1" colSpan="1"></th>
                                        <th rowSpan="1" colSpan="1">Products</th>
                                        <th colSpan="3">Business</th>
                                        <th colSpan="2">Pricing</th>
                                        <th colSpan="3">Price Range (High-Low)</th>
                                        <th colSpan="2">Time Below MP</th>
                                        <th colSpan="3">Avg. Selling Price</th>
                                    </tr>
                                    <tr className='second_tblrow'>
                                        <th style={{ width: '120px' }}></th>
                                        <th style={{ width: '320px' }}></th>
                                        <th style={{ width: '100px' }}>Cont.</th>
                                        <th style={{ width: '100px' }}>Sales</th>
                                        <th style={{ width: '100px' }}>PoP</th>
                                        <th style={{ width: '100px' }}>Latest</th>
                                        <th style={{ width: '100px' }}>Recomm.</th>
                                        <th style={{ width: '200px' }}>Prev.</th>
                                        <th style={{ width: '200px' }}>Curr.</th>
                                        <th style={{ width: '100px' }}>M.P.PoP</th>
                                        <th style={{ width: '100px' }}>Prev.</th>
                                        <th style={{ width: '100px' }}>Curr.</th>
                                        <th style={{ width: '100px' }}>Prev.</th>
                                        <th style={{ width: '100px' }}>Curr.</th>
                                        <th style={{ width: '100px' }}>PoP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {priceContTableData?.length ? <>
                                    {priceContTableData.map((row, index) => (
                                        <tr key={index}>
                                            <td className={`${row.STATUS === 'I' || row.STATUS === 'HP' ? 'td-column-increase' : row.STATUS === 'D' || row.STATUS === 'LP' ? 
                                            'td-column-decrease' : row.STATUS === 'E' || row.STATUS === 'WIN' ? 'td-column-equal' : row.STATUS === 'WPW' || row.STATUS === 'NP' ?
                                             'td-column-wp' : 'td-column-noprice'}`}>{dataStatusformat(row.STATUS)}</td>
                                            <td>{dataProducts(row.PRODUCTS)} - {row?.ITEM_ID}</td>
                                            <td>{dataValueformat(row.BUSINESS_CONT)}</td>
                                            <td>{CurrencyFormatterVal(row.BUSINESS_SALES)}</td>
                                            <td>{dataPopValueformat(row.BUSINESS_POP)}</td>
                                            <td>{decimalCurrencyFormat(row.PRICING_CONTRI)}</td>
                                            <td>{decimalCurrencyFormat(row.PRICING_RECOMEND)}</td>
                                            <td>{numPriceRangeFormatPre(row.PRICE_RANGE_PREV_HIGH,row)}</td>
                                            <td>{numPriceRangeFormatcur(row.PRICE_RANGE_CURR_HIGH,row)}</td>
                                            <td >{dataPopValueformat(row.PRICE_RANGE_POP)}</td>
                                            <td>{dataValueformat(row?.TIME_MP_PREV)}</td>
                                            <td>{dataValueformat(row?.TIME_MP_CURR)}</td>
                                            <td>{decimalCurrencyFormat(row.AVG_SELL_PRICE_PREV)}</td>
                                            <td>{decimalCurrencyFormat(row.AVG_SELL_PRICE_CURR)}</td>
                                            <td>{dataPopValueformat(row.AVG_SELL_PRICE_POP)}</td>
                                        </tr> 
                                    ))}</>: <tr> <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td></tr>}
                                </tbody>
                            </Table>
                        </div>
                    <div className='fulltextbtm f-13'><b> Cont.</b>: <span>Business Contribution</span> <b> Recomm.</b>: <span>Business Recommended Price </span>
                    <b> M.P.PoP</b>: <span>Change in Mean Price Period over Period</span> <b> Prev.</b>: <span>Previous Period </span><b> Curr.</b>: <span>Current Period</span></div>   
                </div> 
                </>}              
            </div>
        )
    };

    const sortByStatus = (a, b) => {
        const order = ['Higher Price', 'Lower Price', 'Within Range (+/-5%)', 'Without Price(K-C)', 'No Comparision available'];
        return order.indexOf(a.VIEW) - order.indexOf(b.VIEW);
    };
    
    const PricingWarTab = () => {
        const sortedData =  priceWarSummaryTable?.responseData?.length ? priceWarSummaryTable?.responseData.sort(sortByStatus) : priceWarSummaryTable?.responseData;
        return (
            <div>
               <div className='text-center mt-3 font-weight-bold pricingheader_title'>
                    Price War
                </div> 
                <div className='text-center mt-2'>
                    <Form.Group controlId="formBasicSelect">
                        <Form.Label className='d-inline mr-3'>Compare to:</Form.Label>
                        <Form.Control className='d-inline col-2' id='compareRetiler'
                        as="select"
                        name={compareName}
                        value={compareVal}
                        onChange={e => {
                            var selectedoption = document.getElementById("compareRetiler");
                            var option= selectedoption.options[selectedoption.selectedIndex];   
                            setCompareVal(e.target.value);setCompareName(option.getAttribute("name"))}}>
                        {priceWarCompareRetailer?.responseData.map((compare, index)=>{                
                            return <option key={index} name={compare?.COMPNAME} value={compare?.COMPKEY}>{compare?.COMPNAME}</option>
                        })}  
                        </Form.Control>
                    </Form.Group>  
                </div>
                {loading ? loadpage() : 
                <>
                <div className='row mt-5 ml-2 mr-2'>
                    <div className='col-6 pl-0 pr-0'>
                        <div className='font-weight-bold f-20 text-center pb-2'>Count of SKUs</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <ComposedChart width={600} data={priceWarCountofSKU?.responseData} margin={{top:12, bottom: 35,right:-20}}>
                                <XAxis dataKey="TIMEFRAME"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} minTickGap={-200} axisLine={false} dy={1} />
                                <YAxis domain={['dataMin', 'dataMax']}  yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} /> 
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} />
                                <CartesianGrid stroke="#e8eaec" />
                                <Area yAxisId="left" dataKey="HP" isAnimationActive={false} name='Higher Price'
                                 fill="#a4de6c" stackId="1" stroke="#a4de6c" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="LP" isAnimationActive={false} name='Lower Price'
                                  stroke="#ffb3b3" stackId="1" fill="#ffb3b3" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="WIN" isAnimationActive={false} name='Within range(+/-5%)'
                                    stroke="#919695" stackId="1" fill="#919695" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="WPW" isAnimationActive={false} name='Without Price'
                                    stroke="#ffd11a" stackId="1" fill="#ffd11a" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="NCP" isAnimationActive={false} name='No Competition'
                                    stroke="#df9e4e" stackId="1" fill="#df9e4e" strokeWidth="0" dot={false} />
                                <Line yAxisId="right" dataKey="SI" isAnimationActive={false} name='Sellout Index' 
                                    stroke="#ff7300" fill='#ff7300' strokeWidth="2" dot={false} />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px", fontSize:'16px',paddingLeft:'10px',paddingRight:'10px' }} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='col-6 pl-0 pr-0'>
                    <div className='font-weight-bold f-20 text-center pb-2'>Business Contribution</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <ComposedChart width={600} data={priceWarBusinessCont?.responseData} margin={{top:12, bottom: 35, right:-20 }}>
                                <XAxis dataKey="TIME"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} minTickGap={-200} axisLine={false} dy={1} />
                                <YAxis domain={['dataMin', 'dataMax']}  yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={numberPriceMult} />
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={numberPricewithoutMulti} /><CartesianGrid stroke="#e8eaec" />
                                <Area yAxisId="left" dataKey="HP" isAnimationActive={false} name='Higher Price'
                                 fill="#a4de6c" stackId="1" stroke="#a4de6c" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="LP" isAnimationActive={false} name='Lower Price'
                                  stroke="#ffb3b3" stackId="1" fill="#ffb3b3" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="WIN" isAnimationActive={false} name='Within range(+/-5%)'
                                    stroke="#a5a5a5" stackId="1" fill="#a5a5a5" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="WPW" isAnimationActive={false} name='Without Price'
                                    stroke="#ffd11a" stackId="1" fill="#ffd11a" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="NCP" isAnimationActive={false} name='No Competition'
                                    stroke="#df9e4e" stackId="1" fill="#df9e4e" strokeWidth="0" dot={false} />
                                <Line yAxisId="right" dataKey="SI" isAnimationActive={false} name='Sellout Index' 
                                    stroke="#ff7300" fill='#ff7300' strokeWidth="2" dot={false} />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px", fontSize:'16px',paddingLeft:'10px',paddingRight:'10px' }} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className='row mt-2 ml-2 mr-2'>                              
                    <div className='col-6 insightpricetable pl-5'> 
                    <div className='font-weight-bold f-20 text-center pt-5 pb-3'></div>                       
                    <Table bordered className='table_center'>
                                    <thead>
                                        <tr className='tablerow_first'>
                                            <th rowSpan="1"></th>
                                            <th colSpan="2">SKU Count</th>
                                            <th colSpan="2">Business Contribution</th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span onClick={viewSkuDetails} className='newValueformat' >View SKU Details</span>
                                            </th>
                                            <th>Count</th>
                                            <th>PoP</th>
                                            <th>Value</th>
                                            <th>PoP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {sortedData?.length > 0 ?<>
                                        {sortedData.map((row, index) => (
                                            <tr key={index}>
                                                <td>{firstWordUpper(row.VIEW)}</td>
                                                <td>{dataCountformat(row.CNT_SKU)}</td>
                                                <td>{dataPopValueformat(row.POP_SKU)}</td>
                                                <td>{dataValueformatBucont(row.CNT_BC)}</td>
                                                <td>{dataPopValueformat(row.POP_BC)}</td>
                                            </tr>
                                        ))}</>: <tr> <td colSpan="5" style={{ textAlign: 'center' }}>No data available</td></tr>}
                                    </tbody>
                                </Table>
                    </div>
                    <div className='col-6 pl-0 pr-0'>
                    <div className='font-weight-bold f-20 text-center pb-1'>Weighted Price Index vs Sales</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <LineChart data={priceWarSellOut?.responseData} margin={{top:12, bottom: 35, right:-20 }}>
                                <XAxis dataKey="TIMEFRAME_VAL"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} dy={1} minTickGap={-200} axisLine={false}/>
                                <YAxis yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} />
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={labelFormatterFixedoneDeci} />
                                <CartesianGrid stroke="#e8eaec" />
                                <Line yAxisId="left" dataKey="SALES" isAnimationActive={false} name='Sales'
                                    stroke="#ed853e" strokeWidth="2.5" dot={ false } />
                                <Line yAxisId="right" dataKey="WEIGHTED_PRICE_INDEX" isAnimationActive={false} name='Weighted Price Index'
                                    stroke="#6b8fd0" strokeWidth="2.5" dot={ false } />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px",fontSize:'16px', paddingLeft:'10px',paddingRight:'10px' }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className='insightpricetable price-top-cont m-3'> 
                    <div><div className='text-center f-24 font-weight-bold'>Top Contributors</div> <div onClick={() => downloadData('pwTableData')} className='text-right downloadfile'>Download All SKUs <ExcelfileIcon /></div></div>
                    
                    <div className={priceWarTopContributionTable?.responseData?.length ? 'tablecls_scrl' : ''}>
                    <Table bordered className='table_center'>
                                <thead className='sticky-top'>
                                    <tr className='tablerow_first'>
                                        <th rowSpan="1" colSpan="1" ></th>
                                        <th rowSpan="1" colSpan="1" >Products</th>
                                        <th colSpan="3">Business</th>
                                        <th colSpan="2">Price</th>
                                        <th colSpan="4">Retailer Price Comparison</th>
                                        <th colSpan="2">ASP</th>
                                        <th colSpan="3">K-C PI vs. Market ASP</th>
                                    </tr>
                                    <tr className='second_tblrow'>
                                        <th style={{ width: '120px' }}></th>
                                        <th style={{ width: '320px' }}></th>
                                        <th style={{ width: '100px' }}>Contrib.</th>
                                        <th style={{ width: '100px' }}>Sales</th>
                                        <th style={{ width: '100px' }}>Sales PoP</th>
                                        <th style={{ width: '90px' }}>Curr.</th>
                                        <th style={{ width: '90px' }}>Recom.</th>
                                        <th style={{ width: '120px' }}>{compareName}</th>
                                        <th style={{ width: '120px' }}>Lowest Mkt</th>
                                        <th style={{ width: '100px' }}>% Time O/U</th>
                                        <th style={{ width: '100px' }}>Best</th>
                                        <th style={{ width: '100px' }}>K-C</th>
                                        <th style={{ width: '100px' }}>Market</th>
                                        <th style={{ width: '100px' }}>CP</th>
                                        <th style={{ width: '100px' }}>PP</th>
                                        <th style={{ width: '100px' }}>Best</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {priceWarTopContributionTable?.responseData.length > 0 ? <>
                                    {priceWarTopContributionTable?.responseData.map((row, index) => (
                                        <tr key={index}>
                                            <td className={columnClassNameFormat(row)}>{dataStatusformat(row.STATUS)}</td>
                                            <td>{dataProducts(row.PRODUCTS)} - {row?.ITEM_ID}</td>
                                            <td>{dataValueformat(row.BUSINESS_CONT)}</td>
                                            <td>{CurrencyFormatterVal(row.BUSINESS_SALES)}</td>
                                            <td>{dataPopValueformat(row.BUSINESS_POP)}</td>
                                            <td>{decimalCurrencyFormat(row.PRICING_CONTRI)}</td>
                                            <td>{decimalCurrencyFormat(row.PRICING_RECOMEND)}</td>
                                            <td>{decimalCurrencyFormat(row.RPC_WALMART)}</td>
                                            <td>{decimalCurrencyFormat(row.RPC_LOWST_MKT)}</td>
                                            <td className={columnClassNameFormat(row)}>{dataValueformat(row.RPC_TIME_OU)}</td>
                                            <td>{retailerdataFormat(row?.RPC_BEST)}</td>
                                            <td>{decimalCurrencyFormat(row.ASP_KC)}</td>
                                            <td>{decimalCurrencyFormat(row.ASP_MARKET)}</td>
                                            <td>{numberPricewithoutMulti(row.PI_CP)}</td>
                                            <td>{numberPricewithoutMulti(row.PI_PP)}</td>
                                            <td>{retailerdataFormat(row.PI_BEST)}</td>
                                        </tr>
                                    ))}</>: <tr> <td colSpan="16" style={{ textAlign: 'center' }}>No data available</td></tr>}
                                </tbody>
                            </Table> 
                        </div>
                    <div className='fulltextbtm f-13'><b> Cont.</b>: <span>Business Contribution</span> <b> PoP</b>: <span> Period over Period </span>
                    <b> Curr.</b>: <span>Current Price</span><b> Recom.</b>: <span>Recommended Price</span> <b> % Time O/U</b>: <span>Percentage of Time Over/Under Index </span> <b> PI</b>: <span>Price Index </span> <b> PP</b>: <span>Previous Period </span><b> CP.</b>: <span>Current Period</span></div>   
                </div> 
                </>}
            </div>
        )
    }

    const CompetativePricingTab = () => {
        const sortedData =  priceCompetitionSummaryTable?.responseData?.length ? priceCompetitionSummaryTable?.responseData.sort(sortByStatus) : priceCompetitionSummaryTable?.responseData;
        const processedDataBC = priceCompetationBusinessCnt?.responseData?.map(item => ({
            ...item,
            LP: item.LP === null ? 0 : item.LP,
            HP: item.HP === null ? 0 : item.HP,
            WIN: item.WIN === null ? 0 : item.WIN,
            WPW: item.WPW === null ? 0 : item.WPW,
            NCP: item.NCP === null ? 0 : item.NCP
          }));
        return (
            <div>
               <div className='text-center mt-3 font-weight-bold pricingheader_title'>
                    Price Competition 
                </div> 
                <div className='text-center mt-2'>
                    <Form.Group controlId="formBasicSelect">
                        <Form.Label className='d-inline mr-3'>Compare to:</Form.Label>
                        <Form.Control className='d-inline col-2' id='competitionRetiler'
                        as="select"
                        name={priceCompetitonName}
                        value={priceCompetitonVal}
                        onChange={e => {
                            var selectedoption = document.getElementById("competitionRetiler");
                            var option= selectedoption.options[selectedoption.selectedIndex];   
                            setPriceCompetitonVal(e.target.value);setPriceCompetitonName(option.getAttribute("name"))}}>
                            {/* <option name="All Manufacturers" value="All Manufacturers">All Manufacturers</option> */}
                        {priceCompetitonRetailer?.responseData.map((compare, index)=>{                
                            return <option key={index} name={compare?.COMPNAME} value={compare?.COMPKEY}>{compare?.COMPNAME}</option>
                        })}  
                        </Form.Control>
                    </Form.Group>  
                </div>
                {loading ? loadpage() : 
                <>
                <div className='row mt-5 ml-2 mr-2'>
                    <div className='col-6 pl-0 pr-0'>
                        <div className='font-weight-bold f-20 text-center pb-2'>Count of SKUs</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <ComposedChart width={600} data={priceCompetationCountofSKU?.responseData} margin={{top:12, bottom: 35,right:-20}}>
                                <XAxis dataKey="TIMEFRAME"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} minTickGap={-200} axisLine={false} dy={1} />
                                <YAxis domain={['dataMin', 'dataMax']}  yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} /> 
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} />
                                <CartesianGrid stroke="#e8eaec" />
                                <Area yAxisId="left" dataKey="HP" isAnimationActive={false} name='Higher Price'
                                 fill="#a4de6c" stackId="1" stroke="#a4de6c" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="LP" isAnimationActive={false} name='Lower Price'
                                  stroke="#ffb3b3" stackId="1" fill="#ffb3b3" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="WIN" isAnimationActive={false} name='Within range(+/-5%)'
                                    stroke="#919695" stackId="1" fill="#919695" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="WPW" isAnimationActive={false} name='Without Price'
                                    stroke="#ffd11a" stackId="1" fill="#ffd11a" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="NCP" isAnimationActive={false} name='No Competition'
                                    stroke="#df9e4e" stackId="1" fill="#df9e4e" strokeWidth="0" dot={false} />
                                <Line yAxisId="right" dataKey="SI" isAnimationActive={false} name='Sellout Index' 
                                    stroke="#ff7300" fill='#ff7300' strokeWidth="2" dot={false} />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px", fontSize:'16px',paddingLeft:'10px',paddingRight:'10px' }} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='col-6 pl-0 pr-0'>
                    <div className='font-weight-bold f-20 text-center pb-2'>Business Contribution</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <ComposedChart width={600} data={processedDataBC} margin={{top:12, bottom: 35, right:-20 }}>
                                <XAxis dataKey="TIME"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} minTickGap={-200} axisLine={false} dy={1} />
                                <YAxis domain={['dataMin', 'dataMax']}  yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={numberPriceMult} />
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={numberPricewithoutMulti} /><CartesianGrid stroke="#e8eaec" />
                                <Area yAxisId="left" dataKey="HP" isAnimationActive={false} name='Higher Price'
                                 fill="#a4de6c" stackId="1" stroke="#a4de6c" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="LP" isAnimationActive={false} name='Lower Price'
                                  stroke="#ffb3b3" stackId="1" fill="#ffb3b3" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="WIN" isAnimationActive={false} name='Within range(+/-5%)'
                                    stroke="#a5a5a5" stackId="1" fill="#a5a5a5" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="WPW" isAnimationActive={false} name='Without Price'
                                    stroke="#ffd11a" stackId="1" fill="#ffd11a" strokeWidth="0" dot={false} />
                                <Area yAxisId="left" dataKey="NCP" isAnimationActive={false} name='No Competition'
                                    stroke="#df9e4e" stackId="1" fill="#df9e4e" strokeWidth="0" dot={false} />
                                <Line yAxisId="right" dataKey="SI" isAnimationActive={false} name='Sellout Index' 
                                    stroke="#ff7300" fill='#ff7300' strokeWidth="2" dot={false} />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px", fontSize:'16px',paddingLeft:'10px',paddingRight:'10px' }} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                 <div className='row mt-2 ml-2 mr-2'>
                    <div className='col-6 insightpricetable pl-5'> 
                    <div className='font-weight-bold f-20 text-center pt-5 pb-3'></div>                       
                        
                        <Table bordered className='table_center'>
                                    <thead>
                                        <tr className='tablerow_first'>
                                            <th rowSpan="1"></th>
                                            <th colSpan="2">SKU Count</th>
                                            <th colSpan="2">Business Contribution</th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span onClick={viewSkuDetails} className='newValueformat' >View SKU Details</span>
                                            </th>
                                            <th>Count</th>
                                            <th>PoP</th>
                                            <th>Value</th>
                                            <th>PoP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {sortedData?.length > 0 ?<>
                                        {sortedData.map((row, index) => (
                                            <tr key={index}>
                                                <td>{firstWordUpper(row.VIEW)}</td>
                                                <td>{dataCountformat(row.CNT_SKU)}</td>
                                                <td>{dataPopValueformat(row.POP_SKU)}</td>
                                                <td>{dataValueformatBucont(row.CNT_BC)}</td>
                                                <td>{dataPopValueformat(row.POP_BC)}</td>
                                            </tr>
                                        ))}</>: <tr> <td colSpan="5" style={{ textAlign: 'center' }}>No data available</td></tr>}
                                    </tbody>
                                </Table>
                    </div>
                    <div className='col-6 pl-0 pr-0'>
                    <div className='font-weight-bold f-20 text-center pb-1'>Weighted Price Index vs Sales</div>
                        <ResponsiveContainer width="100%" height={350}>
                            <LineChart data={priceCompetitionSellOut?.responseData} margin={{top:12, bottom: 35, right:-20 }}>
                                <XAxis dataKey="TIMEFRAME_VAL"  textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} dy={1} minTickGap={-200} axisLine={false}/>
                                <YAxis yAxisId="left" style={{ fontSize: "10px" }} tickFormatter={labelFormatterNumcupr} />
                                <YAxis yAxisId="right" orientation="right" style={{ fontSize: "10px" }} tickFormatter={labelFormatterFixedoneDeci} />
                                <CartesianGrid stroke="#e8eaec" />
                                <Line yAxisId="left" dataKey="SALES" isAnimationActive={false} name='Sales'
                                    stroke="#ed853e" strokeWidth="2.5" dot={ false } />
                                <Line yAxisId="right" dataKey="WEIGHTED_PRICE_INDEX" isAnimationActive={false} name='Weighted Price Index'
                                    stroke="#6b8fd0" strokeWidth="2.5" dot={ false } />
                                <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />}/>
                                <Legend wrapperStyle={{ paddingTop: "30px",fontSize:'16px', paddingLeft:'10px',paddingRight:'10px' }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    </div>
                <div className='insightpricetable price-top-cont m-3'> 
                    <div><div className='text-center f-24 font-weight-bold'>Top Contributors</div> <div onClick={() => downloadData('pcTableData')} className='text-right downloadfile'>Download All SKUs <ExcelfileIcon /></div></div>
                    
                    <div className={priceContributionTopContributionTable?.responseData?.length > 0 ? 'tablecls_scrl' : ''}>
                    <Table bordered className='table_center'>
                                <thead className='sticky-top'>
                                    <tr className='tablerow_first'>
                                        <th rowSpan="1" colSpan="1" ></th>
                                        <th rowSpan="1" colSpan="1" >Products</th>
                                        <th rowSpan="1" colSpan="1">Business Contribution</th>
                                        <th rowSpan="1" colSpan="1">Latest price</th>
                                        <th rowSpan="1" colSpan="1">Recommended Price</th>
                                        <th colSpan="3">Lowest Price</th>
                                        <th colSpan="2">ASP</th>
                                        <th colSpan="2">ASP Index</th>
                                    </tr>
                                    <tr className='second_tblrow'>
                                        <th style={{ width: '120px' }}></th>
                                        <th style={{ width: '320px' }}></th>
                                        <th style={{ width: '100px' }}></th>
                                        <th style={{ width: '100px' }}></th>
                                        <th style={{ width: '100px' }}></th>
                                        <th style={{ width: '100px' }}>K-C</th>
                                        <th style={{ width: '100px' }}>Comp</th>
                                        <th style={{ width: '120px' }}>% time won/lost</th>
                                        <th style={{ width: '120px' }}>ASP_KC</th>
                                        <th style={{ width: '100px' }}>COMP_KC</th>
                                        <th style={{ width: '100px' }}>PI_CP</th>
                                        <th style={{ width: '100px' }}>PI_PP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {priceContributionTopContributionTable?.responseData.length > 0 ? <>
                                    {priceContributionTopContributionTable?.responseData.map((row, index) => (
                                        <tr key={index}>
                                            <td className={columnClassNameFormat(row)}>{dataStatusformat(row.STATUS)}</td>
                                            <td>{dataProducts(row.PRODUCTS)} - {row?.ITEM_ID}</td>
                                            <td>{dataValueformat(row.BUSINESS_CONT)}</td>
                                            <td>{decimalCurrencyFormat(row.PRICING_CONTRI)}</td>
                                            <td>{decimalCurrencyFormat(row.PRICING_RECOMEND)}</td>
                                            <td>{decimalCurrencyFormat(row.LOWEST_PRICE)}</td>
                                            <td>{decimalCurrencyFormat(row.LOWEST_COM_PRICE)}</td>
                                            <td>{dataPopValueformat(row.TIME_OU)}</td>
                                            <td>{decimalCurrencyFormat(row?.ASP_KC)}</td>
                                            <td>{decimalCurrencyFormat(row.COMP_KC)}</td>
                                            <td>{dataPopValueformat(row.PI_CP)}</td>
                                            <td>{numberPricewithoutMulti(row.PI_PP)}</td>
                                        </tr>
                                    ))}</>: <tr> <td colSpan="16" style={{ textAlign: 'center' }}>No data available</td></tr>}
                                </tbody>
                            </Table> 
                        </div>
                    <div className='fulltextbtm f-13'><b> Cont.</b>: <span>Business Contribution</span> <b> PoP</b>: <span> Period over Period </span>
                    <b> Curr.</b>: <span>Current Price</span><b> Recom.</b>: <span>Recommended Price</span> <b> % Time O/U</b>: <span>Percentage of Time Over/Under Index </span> <b> PI</b>: <span>Price Index </span> <b> PP</b>: <span>Previous Period </span><b> CP.</b>: <span>Current Period</span></div>   
                </div>  
                </>}
            </div>
        )
    }
    
    const loadpage = () => {
        return (<div id="strategy_wrapper">
            <div className="container-fluid  default-top loading_wrapper bg-w p-5">
                <div className="sweet-loading">
                    <span><b>Processing...</b></span>
                    <BarLoader width={100} color="#123abc" />
                </div>
            </div>
        </div>)
    }
    if(firstloading) {
        return loadpage();
    } else {
    return (
        <div className='mb-5'>
            <div className='d-flex justify-content-end mt-4 mb-2'>
                <button className={`tabbtn tabpricing ${activeTab === 'tab1' ? 'selectedbtn' : ''}`} onClick={() => handleTabChange('tab1')}>
                    Price Fluctuation
                </button>
                {priceCompetitonRetailer?.responseData?.length > 0 &&
                <button className={`tabbtn tabpricing ${activeTab === 'tab2' ? 'selectedbtn' : ''}`} onClick={() => handleTabChange('tab2')}>
                    Competitive Pricing
                </button> }
                {priceWarCompareRetailer?.responseData?.length > 0 && priceWarCompareRetailer?.showPW &&
                <button className={`tabbtn tabpricing ${activeTab === 'tab3' ? 'selectedbtn' : ''}`} onClick={() => handleTabChange('tab3')}>
                    Retailer Pricing
                </button> }
            </div>
            {displayModal && <SentimentReportModal modalDetails={modalDetails} display={displayModal} displayModalOff={displayModalOff} />}
            {activeTab === 'tab1' && PricingTab()}
            {activeTab === 'tab2' && CompetativePricingTab()} 
            {activeTab === 'tab3' && PricingWarTab()}           
        </div>
    );
    }    
};
export default PriceFluctuation;