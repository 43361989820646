import React, { useState, useEffect } from "react";
import Filters from "../../components/Filters";
import NewInsightsTable from "./NewInsightsTable";
import "./NewInsights.scss";
import NewInsightsChart from "./NewInsightsChart";
import {
	getInsightsTabDetails,
	getInsightsTabs,
	getInsightsTabDetailsNew,
	getLastDataRefreshDate,
	getLastDataRefreshDatePF,
	saveDateRangeFilter,
	insightsStartEndDates,
} from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import NewInsightsTopMoversTable from "./NewInsightsTopMoversTable";
import LatestModal from "../../components/LatestModal";
import { useHistory, useLocation } from "react-router-dom";
import NewInsightsDigitalShelfChart from "./NewInsightsDigitalShelfChart";
import SalesPredictionSection from "./SalesPredictionSection";
import SentmentSection from "./SentmentSection";
import PriceFluctuation from "./PriceFluctuation";
import BarLoader from "react-spinners/BarLoader";
import { addCommonActivityCaptureDetail } from "../../helper";
import { data } from "jquery";

const NewInsights = () => {
	let dispatch = useDispatch();
	const [showTextMsg, setShowTextMsg] = useState("");
	const [tabName, setTabName] = useState();
	const [loading, setLoading] = useState(true);
	const [rtloading, setRtLoading] = useState(false);
	const [digitalflag, setDigitalflag] = useState(false);
	let {
		selectedCustomerKey,
		selectedGlobalFilters,
		subproject,
		getLastDataRefreshDataPF,
	} = useSelector((state) => state.globalReducer);
	let refreshDatapf = getLastDataRefreshDataPF?.responseData;
	let lastDaterefreshDatapf = refreshDatapf?.lastDataRefreshDate;
	let transMaxdatapf = refreshDatapf?.transMaxDate;
	let ytdstartdatapf = refreshDatapf?.ytdStartDate;
	let lastDataRefreshDate = useSelector(
		(state) =>
			state.globalReducer?.getLastDataRefreshDate?.responseData
				?.lastDataRefreshDate
	);
	let ytdStartDate = useSelector(
		(state) =>
			state.globalReducer?.getLastDataRefreshDate?.responseData
				?.ytdStartDate
	);
	let transMaxDate = useSelector(
		(state) =>
			state.globalReducer?.getLastDataRefreshDate?.responseData
				?.transMaxDate
	);
	let { dateFilterAndRange } = useSelector(
		(state) => state.newRecommendationTableData
	);

	let { insightsTabsData, insightsTabsDetailsData } = useSelector(
		(state) => state.insightReducer
	);

	let finaldataSectionName = insightsTabsDetailsData?.finalData?.SECTION_NAME;

	const [leftSectionName, setLeftSectionName] = useState();
	const [tabsDataResponse, setTabsDataResponse] = useState([]);
	const [selectedDateRange, setSelectedDateRange] = useState("MTD");
	const [compareToOption, setCompareToOption] = useState("Prior period");
	const [displayLatestModal, setDisplayLatestModal] = useState(false);
	let selectedDateRangenew = "MTD";
	const retailerlistSoGraph = [
		"Coupang-eCom-Korea",
		"Shopee-eCom-Malaysia",
		"Shopee-eCom-Philippines",
		"Shopee-eCom-Thailand",
		"Shopee-eCom-Singapore",
		"Shopee-eCom-Vietnam",
		"Detmir-Russia",
		"Flipkart-India",
		"Tesco-UK",
		"eBay-eCom-Korea",
		"MagazineLuiza-BR",
	];

	useEffect(() => {
		if (
			dateFilterAndRange?.dateRange !== undefined &&
			dateFilterAndRange?.dateRange !== ""
		) {
			setSelectedDateRange(dateFilterAndRange?.dateRange);
			setCompareToOption("Prior period");
		}
	}, [dateFilterAndRange?.dateRange]);

	const [filterDateRange, setFilterDateRange] = useState({
		selectedDateRange: selectedDateRange,
		compareToOption: compareToOption,
	});

	const responseInsightTabs = (response) => {
		if (response?.data?.responseData?.tabList?.length === 0) {
			setLoading(false);
			setShowTextMsg("No data available to display");
		}
		if (response?.data?.responseData?.tabList?.length > 0) {
			setTabsDataResponse(response.data.responseData.tabList);
			setLeftSectionName(
				response.data.responseData.tabList[0].SECTION_NAME
			);
			setShowTextMsg("");
			setLoading(false);
		} else {
			console.log("oops, it seems you overloaded the server");
			window.location.reload();
		}
	};

	useEffect(() => {
		document.title = `Insights`;
		if (selectedCustomerKey !== undefined) {
			dispatch(
				getInsightsTabs(
					{ customerKey: selectedCustomerKey },
					responseInsightTabs
				)
			);
		}
	}, [selectedCustomerKey, dispatch]);

	const responseHandlerupdate = (response) => {
		if (response?.data?.responseData) {
			setRtLoading(false);
		}
	};

	useEffect(() => {
		if (
			leftSectionName === "Top Movers" ||
			leftSectionName === "Sentiment"
		) {
			dispatch(
				getLastDataRefreshDate({
					projectName: "eCompass",
					retailerName: "Walmart",
					dashboardName: "Insights",
				})
			);
		}
		if (leftSectionName === "Pricing") {
			dispatch(
				getLastDataRefreshDatePF({
					projectName: "eCompass",
					retailerName: subproject,
					dashboardName: "Insights",
				})
			);
		}
		if (tabsDataResponse?.length) {
			dispatch(
				getInsightsTabDetailsNew(
					{
						customerKey: selectedCustomerKey,
						sectionName: leftSectionName,
					},
					responseHandlerupdate
				)
			);
		}
		if (
			leftSectionName === "Digital Shelf" ||
			leftSectionName === "Sentiment" ||
			leftSectionName === "Pricing"
		) {
			setDigitalflag(true);
		} else {
			setDigitalflag(false);
		}
	}, [
		dispatch,
		leftSectionName,
		selectedCustomerKey,
		subproject,
		tabsDataResponse?.length,
	]);

	useEffect(() => {
		if (leftSectionName !== undefined) {
			addCommonActivityCaptureDetail({
				page: `Insights-${leftSectionName}`,
				globalFilters: selectedGlobalFilters,
			});
		}
	}, [leftSectionName, selectedCustomerKey, selectedGlobalFilters]);

	const handleDisplayNameClick = (colName) => {
		setTabName(colName?.DISPLAY_NAME);
	};
	const displaySubSection = () => {
		if (
			insightsTabsDetailsData !== undefined &&
			insightsTabsDetailsData?.tabDetails !== undefined &&
			insightsTabsDetailsData?.tabDetails[leftSectionName]?.length > 0 &&
			leftSectionName === "Sales Overview"
		) {
			return insightsTabsDetailsData.tabDetails[leftSectionName].map(
				(colName, index) => {
					if (index === 0 || index === 1) {
						return (
							<div
								className='togglebtn'
								key={index}
								onClick={(e) => {
									handleDisplayNameClick(colName);
								}}
							>
								<button
									className={
										tabName === colName?.DISPLAY_NAME
											? "selectedbtn btn"
											: index === 0 &&
											  tabName === undefined
											? "selectedbtn btn"
											: "btn"
									}
								>
									{" "}
									{colName?.DISPLAY_NAME}
								</button>
							</div>
						);
					}
				}
			);
		}
	};

	const handleleftMenuClick = (colName) => {
		if (leftSectionName !== colName?.SECTION_NAME) {
			setLeftSectionName(colName?.SECTION_NAME);
			setRtLoading(true);
			setTabName();
			if (selectedDateRange !== "MTD") {
				document.cookie =
					"selected-date-range-filters= ; path=/; max-age=0;";
				dispatch(
					saveDateRangeFilter({
						dateRange: "MTD",
						compareTo: "Prior Period",
					})
				);
				setSelectedDateRange("MTD");
				setCompareToOption("Prior Period");
				setFilterDateRange({
					selectedDateRange: "MTD",
					compareToOption: "Prior Period",
				});
			}
			dispatch({
				type: "SAVE_SECTION_NAME",
				payload: {
					sectionName: colName?.SECTION_NAME,
					metricName: insightsTabsDetailsData?.finalData?.METRIC_NAME,
				},
			});
		}
	};
	const leftMenu = () => {
		if (tabsDataResponse?.length > 0) {
			return tabsDataResponse.map((colName, index) => {
				return (
					<div
						onClick={(e) => {
							handleleftMenuClick(colName);
						}}
						key={index}
						className={
							leftSectionName === colName?.SECTION_NAME
								? " sidebar-sales left_section"
								: index === 0 && leftSectionName === ""
								? " sidebar-sales left_section"
								: "left_section"
						}
					>
						{" "}
						{colName?.SECTION_NAME}
					</div>
				);
			});
		}
	};

	useEffect(() => {
		dispatch(
			insightsStartEndDates({
				start: undefined,
				end: undefined,
			})
		);
	}, [dispatch, leftSectionName, selectedCustomerKey]);

	const setStartDateAndCompareToPeriod = (
		selectedDateRange,
		compareToOption
	) => {
		setFilterDateRange({
			selectedDateRange: selectedDateRange,
			compareToOption: compareToOption,
		});
	};

	const queryParams = new URLSearchParams(window.location.search);
	const termId = queryParams.get("id");
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (termId !== "" && termId !== null) {
			setDisplayLatestModal(true);
		}
	}, [termId]);

	const displayLatestModalOff = () => {
		let flag = displayLatestModal;
		history.push(location.pathname);
		setDisplayLatestModal(!flag);
	};

	const rightmenusection = [
		"Top Movers",
		"Sales Prediction",
		"Sentiment",
		"Pricing",
	];

	const pageLoading = () => {
		return (
			<div id='strategy_wrapper'>
				<div className='container-fluid  default-top loading_insights loading_wrapper bg-w p-5'>
					<div className='sweet-loading'>
						<span>
							<b>Processing...</b>
						</span>
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<BarLoader width={100} color='#123abc' />
						</div>
					</div>
				</div>
			</div>
		);
	};

	if (loading) {
		return pageLoading();
	} else {
		return (
			<div
				className='container-fluid  default-top ml-5 mb-5'
				style={{ width: "auto" }}
			>
				{displayLatestModal ? (
					<LatestModal
						displayLatestModalOff={displayLatestModalOff}
						releaseByid={termId}
					/>
				) : null}

				<div className='row'>
					<div className='align-items-center w-100 mr-4'>
						<div class='ml-auto newinsight-filter'>
							{((leftSectionName === "Top Movers" ||
								leftSectionName === "Sentiment") &&
								lastDataRefreshDate !== undefined) ||
							(leftSectionName === "Pricing" &&
								lastDaterefreshDatapf !== undefined) ? (
								<Filters
									title='Insights'
									lastRefreshedDate={
										leftSectionName === "Pricing"
											? lastDaterefreshDatapf
											: lastDataRefreshDate
									}
									ytdStartDate={
										leftSectionName === "Pricing"
											? ytdstartdatapf
											: ytdStartDate
									}
									displayDateFilter={true}
									setStartDateAndCompareToPeriod={
										setStartDateAndCompareToPeriod
									}
									selectedDateRange={selectedDateRange}
									compareToOption={compareToOption}
									defaultDateRange={selectedDateRangenew}
									transMaxDate={
										leftSectionName === "Pricing"
											? transMaxdatapf
											: transMaxDate
									}
									newDateDefault={true}
									digitalShelfProp={digitalflag}
								/>
							) : (
								<Filters
									title='Insights'
									displaySpecificdate={
										leftSectionName === "Sales Overview" ||
										leftSectionName === "Sales Drivers"
											? true
											: false
									}
									digitalShelfProp={digitalflag}
									hideFilteredRow={false}
								/>
							)}
						</div>
					</div>

					<div className='col-12'>
						{showTextMsg !== "" ? (
							<div className='text-center mt-5'>
								{" "}
								{showTextMsg}{" "}
							</div>
						) : (
							<div className='row mt-2 insights-marcls bg-white'>
								<div className='left_block pl-0 pr-0 border'>
									<div className='mt-5'>{leftMenu()}</div>
								</div>

								<div className='right_block border'>
									{rtloading ? (
										pageLoading()
									) : (
										<>
											{!rightmenusection.includes(
												leftSectionName
											) && (
												<div className='m-4 mb-3 d-flex justify-content-between top-borderbtm'>
													<h4 className='titlename pt-4'>
														{leftSectionName}
													</h4>
													<div className='d-flex'>
														{retailerlistSoGraph.includes(
															subproject
														) &&
															displaySubSection()}
													</div>
												</div>
											)}
											{leftSectionName ===
												"Sales Overview" &&
												retailerlistSoGraph.includes(
													subproject
												) && (
													<NewInsightsChart
														displayName={tabName}
													/>
												)}
											{leftSectionName ===
												"Digital Shelf" && (
												<NewInsightsDigitalShelfChart
													displayName={tabName}
												/>
											)}
											{!rightmenusection.includes(
												leftSectionName
											) && <NewInsightsTable />}
											{leftSectionName === "Top Movers" &&
												finaldataSectionName ===
													"Top Movers" && (
													<NewInsightsTopMoversTable
														filterDateRange={
															filterDateRange
														}
													/>
												)}

											{leftSectionName ===
												"Sales Prediction" && (
												<SalesPredictionSection />
											)}
											{leftSectionName ===
												"Sentiment" && (
												<SentmentSection />
											)}
											{leftSectionName === "Pricing" && (
												<PriceFluctuation />
											)}
										</>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
};

export default NewInsights;
