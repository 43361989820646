export default function (state = {}, action) {
	switch (action.type) {
		case "GET_NEWINSIGHTS_TABS":
			return { ...state, insightsTabsData: action.payload };
		case "GET_NEWINSIGHTS_TABDETAILSNEW":
			return {
				...state,
				insightsTabsResponseData: action?.payload,
				insightsTabsDetailsData:
					action?.payload?.responseType === "success"
						? action.payload.responseData
						: {},
			};
		case "NEW_INSIGHTS_TABLE":
			return { ...state, insightsTableData: action.payload.responseData };
		case "GET_NEWINSIGHTS_CRISPLINK":
			return {
				...state,
				insightsTableCrispLink: action.payload.responseData,
			};
		case "NEW_INSIGHTS_METRICNAME":
			return { ...state, reportDetailsMetricName: action.payload };
		case "SAVE_SECTION_NAME":
			return { ...state, selectedSelectionAndMetricName: action.payload };
		case "SAVE_TOPMOVERS_OPTIONS":
			return { ...state, selectedtopMoversFilter: action.payload };
		case "GET_RATINGREVIEWS_SUMMARY":
			return { ...state, ratingReviewSummary: action.payload };
		case "PRICING_COUNT_OFSKU":
			return { ...state, pricingCountofSKU: action.payload };
		case "PRICING_BUSINESS_CONT":
			return { ...state, pricingBusinessCont: action.payload };
		case "PRICING_SELLOUT":
			return { ...state, pricingSellOut: action.payload };
		case "PRICING_FLUCTSUMMARY_TABLE":
			return { ...state, pricingFluctSummaryTable: action.payload };
		case "PRICING_COUNT_COMPETITION":
			return { ...state, priceCompetationCountofSKU: action.payload };
		case "PRICECOMPETITION_SUMMARY_TABLE":
			return { ...state, priceCompetitionSummaryTable: action.payload };
		case "PRICECOMPETITION_SELLOUT":
			return { ...state, priceCompetitionSellOut: action.payload };
		case "PRICING_BUSINESS_COMPETATION":
			return { ...state, priceCompetationBusinessCnt: action.payload };
		case "PRICING_COUNT_OFSKUPW":
			return { ...state, priceWarCountofSKU: action.payload };
		case "PRICING_BUSINESS_CONTPW":
			return { ...state, priceWarBusinessCont: action.payload };
		case "PRICING_SELLOUT_PW":
			return { ...state, priceWarSellOut: action.payload };
		case "PRICE_COMPETITOR_LIST":
			return { ...state, priceCompetitonRetailer: action.payload };
		case "PRICEWAR_COMPETITORLIST":
			return { ...state, priceWarCompareRetailer: action.payload };
		case "PRICEWAR_SUMMARY_TABLE":
			return { ...state, priceWarSummaryTable: action.payload };
		case "PRICEWAR_TOPCONTRIBUTION_TABLE":
			return { ...state, priceWarTopContributionTable: action.payload };
		case "PRICECOMPETION_TOPCONTRIBUTION_TABLE":
			return {
				...state,
				priceContributionTopContributionTable: action.payload,
			};
		case "INSIGHTS_DATES_PARAMETERS":
			return { ...state, insightsDatesParamaterdata: action.payload };
		case "INSIGHTS_START_END_DATE":
			return { ...state, insightsStartEndDatesdata: action.payload };
		case "GET_SOYEAR_LIST":
			return { ...state, insightsYearsList: action.payload };
		default:
			return state;
	}
}
